import * as React from 'react'
import type { HeadFC } from 'gatsby'
import MainLayout from '../Layouts/MainLayout'
import Container from '../components/Container'
import * as style from '../scss/markdown-pages.module.scss'
import { graphql } from 'gatsby'
import { SEO } from '../components/seo'

interface TemplateProps {
  markdownRemark: {
    html: string
    frontmatter: {
      date: string
      slug: string
      description: string
      title: string
    }
  }
}

const MdTemplatePage: React.FC<{ data: TemplateProps }> = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <MainLayout>
      <main className={style.page}>
        <div className={style.hero}>
          <Container>
            <h1>{frontmatter.title}</h1>
            <small>Effective Date: {frontmatter.date}</small>
          </Container>
        </div>
        <Container>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
      </main>
    </MainLayout>
  )
}

export default MdTemplatePage

export const Head: HeadFC<TemplateProps> = ({ data }): React.ReactElement => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  return <SEO title={frontmatter.title} pathname={frontmatter.slug} />
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        description
        title
      }
    }
  }
`
